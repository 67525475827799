import { Fragment, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import SearchReservations from '@components/search-reservations';
import { Container } from '@layout';
import api from '@services/api';
import { getStateNameByIata } from '@system/utils';
import { useAuthentication } from '@stores/authentication';
import BreadCrumbs from '../components/breadcrumbs';
import LoadingAnimation from '../components/LoadingAnimation';
import BookingRouteCard from '../components/route-card/BookingRouteCard';
import { PagePath } from '../pages-config';
import './AereoReservasResultados.scss';
import { ButtonV2 } from '@components/new-components/button-v2';
import { Alert } from '@components/alert';

export default function AereoReservasResultados() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [dataBaseAeroportos, setDataBaseAeroportos] = useState([]);
  const { session } = useAuthentication();

  const {
    origin,
    destination,
    departureDate,
    returnDate,
    months,
    adultPassengers,
    childrenPassengers,
    babyPassengers,
  } = getSearch();

  const passengers =
    Number(adultPassengers) +
    Number(childrenPassengers) +
    Number(babyPassengers);

  useEffect(() => {
    let query = 'SELECT * FROM `vpcharter_aeroportos` ';
    let fData = new FormData();
    fData.append('query', query);
    api
      .post('/query', fData)
      .then((response) => {
        if (response.data.length > 0) {
          setDataBaseAeroportos(response.data);
        }
      })
      .catch((error) => alert(error));
  }, []);

  function getSearch() {
    const search = {};
    searchParams.forEach((value, key) => (search[key] = value));
    return search;
  }

  function validateSeat(id) {
    navigate(PagePath.AereoReservasCheckout, {
      state: { charterId: id, step: 'checkout' },
    });
  }

  function checkToday(x) {
    let today = new Date().toISOString().slice(0, 10);

    if (x < today) {
      return false;
    }

    return true;
  }

  // INICIO
  /**
   * @type {ReturnType<typeof useState<CharterSummaryDto[]>>}
   */
  const [flights, setFlights] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [currentQueryType, setCurrentQueryType] = useState('initial'); // Define the current query type

  const [extraFlights, setExtraFlights] = useState([]);
  const [pageExtraFlights, setPageExtraFlights] = useState(1);
  const [totalPagesExtraFlights, setTotalPagesExtraFlights] = useState(1);
  const [loadingExtraFlights, setLoadingExtraFlights] = useState(false);

  useEffect(() => {
    if (currentQueryType === 'initial') {
      return () => fetchFlights(1);
    } else {
      fetchExtraFlights(1);
    }
  }, [currentQueryType]);
  const fetchFlights = async (pageNum = 1) => {
    setLoading(true);
    try {
      const response = await api.get('charters/summaries', {
        params: {
          active: 1,
          originAirport: origin,
          destinationAirport: destination,
          departureDate,
          returnDate,
          months,
          passengers,
          pagination: pageNum,
          limit: 10,
        },
      });
      const data = response.data;
      if (data.result.length <= 0 && page === totalPages) {
        loadMoreExtraFlights();
      }
      setFlights((prevFlights) => [...prevFlights, ...data.result]);
      setTotalPages(data.totalPages);
      setPage(pageNum);
    } catch (error) {
      console.error('Erro ao buscar voos:', error);
    }
    setLoading(false);
  };

  const fetchExtraFlights = async (pageNum = 1) => {
    setLoadingExtraFlights(true);
    try {
      let params = {
        active: 1,
        passengers: 1,
        pagination: pageNum,
        limit: 10,
      };
      if (currentQueryType === 'initial') {
        params = {
          ...params,
          originAirport: origin,
          destinationAirport: destination,
          departureDate,
          returnDate,
          months,
        };
      } else if (currentQueryType === 'sameOriginDestinationDifferentDates') {
        params = {
          ...params,
          originAirport: origin,
          destinationAirport: destination,
        };
      } else if (currentQueryType === 'sameStateOriginDifferentDates') {
        params = {
          ...params,
          originState: `${getStateNameByIata(origin, dataBaseAeroportos).split('/').at(-1)}`, // estado da origem
          destinationAirport: destination,
          excludeDate: departureDate,
        };
      } else if (currentQueryType === 'sameOriginDifferentDestinationDates') {
        params = {
          ...params,
          originAirport: origin,
          excludeDestinationAirport: destination,
          excludeDate: departureDate,
        };
      } else if (currentQueryType !== 'allFlights') {
        return;
      }

      const { data } = await api.get('charters/summaries', { params });
      if (data.result.length === 0) {
        if (currentQueryType !== 'allFlights') {
          loadMoreExtraFlights();
          return;
        }
      }
      setExtraFlights((prevFlights) => [...prevFlights, ...data.result]);
      setTotalPagesExtraFlights(data.totalPages);
      setPageExtraFlights(pageNum);
    } catch (error) {
      console.error('Erro ao buscar voos:', error);
    }
    setLoadingExtraFlights(false);
  };

  const loadMoreFlights = () => {
    if (page < totalPages) {
      fetchFlights(page + 1);
    } else if (page === totalPages) {
      loadMoreExtraFlights();
    }
  };

  const loadMoreExtraFlights = () => {
    switch (currentQueryType) {
      case 'initial':
        setCurrentQueryType('sameOriginDestinationDifferentDates');
        break;
      case 'sameOriginDestinationDifferentDates':
        setCurrentQueryType('sameStateOriginDifferentDates');

        break;
      case 'sameStateOriginDifferentDates':
        setCurrentQueryType('sameOriginDifferentDestinationDates');

        break;
      case 'sameOriginDifferentDestinationDates':
        setCurrentQueryType('allFlights');
        break;
      default:
        break;
    }
  };
  // FIM

  const pageTitle = session?.agency?.nomeFantasia ?? 'Buscar reserva';

  let summarie_title = '';
  const db_length = flights?.filter((d) => d.minPrice).length;
  if (db_length > 1) {
    const dest_city = flights[0].destinationCity.split(`/`)[0];
    summarie_title = `Encontrados ${db_length} resultados para ${dest_city}`;
  } else if (db_length == 1) {
    const dest_city = flights[0].destinationCity.split(`/`)[0];
    summarie_title = `Encontrado 1 resultado para ${dest_city}`;
  }

  // {dataBase.filter((d) => d.minPrice).length}{' '}
  // {dataBase.filter((d) => d.minPrice).length == 1
  //   ? 'resultado foi encontrado'
  //   : 'resultados foram encontrados'}

  return (
    <>
      {loading && <LoadingAnimation />}
      <div className="flex flex-col gap-4">
        <BreadCrumbs
          paths={[
            { label: 'Home', link: PagePath.Home },
            { label: 'Aéreo', link: PagePath.Aereo },
            { label: 'Reservas', link: PagePath.AereoReservas },
            {
              label: 'Listagem de reservas',
              link: PagePath.AereoReservasResultados,
            },
          ]}
        />
        <Container>
          <h2 className="m-0 text-heading-2 text-primary">{pageTitle}</h2>
        </Container>
        <SearchReservations
          origin={origin}
          destination={destination}
          departureDate={departureDate}
          months={months}
          returnDate={returnDate}
          passengers={passengers}
          airports={dataBaseAeroportos}
        />
        <div
          className={
            flights?.length === 0
              ? 'flex w-full items-end justify-between'
              : 'hidden'
          }
        >
          {flights?.length === 0 ? (
            <Alert type="warning">
              <div className="flex flex-col">
                <span className="text-small font-semibold text-neutral-800">
                  Não encontramos resultados para a busca realizada
                </span>
              </div>
            </Alert>
          ) : (
            <>
              {/* <Form.Group className="w-[300px]">
              <Form.Label>Ordenação</Form.Label>
              <SelectV2
              options={ORDER_BY_OPTIONS}
              value={ordenation}
              onChange={(e) => setOrdenation(e.target.value)}
              placeholder="Ordenar por"
              />
              </Form.Group> */}
            </>
          )}
        </div>
      </div>
      <h3
        className={
          flights?.filter((d) => d.minPrice).length > 0
            ? 'm-0 mb-4 mt-[26px] h-max !p-0 text-heading-3 text-neutral-800'
            : 'hidden'
        }
      >
        {summarie_title}
      </h3>

      <div className="!m-0 !max-w-full">
        <div className="table_list">
          <div className="flex flex-col gap-4">
            {flights?.map((data, index) => (
              <BookingRouteCard
                key={index}
                baggage={data.departureBaggage}
                index={index}
                airports={dataBaseAeroportos}
                departure={{
                  company: data.departureAirline,
                  operator: data.departureOperator,
                  flight: data.departureFlight,
                  companyLogo: data.departureAirlineLogo,
                  origin: data.originAirport,
                  originTime: data.departureStartAt,
                  originDate: data.departureDate,
                  destination: data.destinationAirport,
                  destinationTime: data.departureEndAt,
                  destinationDate: data.departureDate,
                  nextDay: data.departureNextDay,
                  totalScale: data.departureTotalScales,
                  totalConnection: data.departureTotalConnections,
                  destionationScale: '',
                  originScale: '',
                  connectionAirpoirt: '',
                }}
                return={{
                  company: data.returnAirline,
                  operator: data.returnOperator,
                  flight: data.returnFlight,
                  companyLogo: data.returnAirlineLogo,
                  origin: data.destinationAirport,
                  originTime: data.returnStartAt,
                  originDate: data.returnDate,
                  destination: data.originAirport,
                  destinationTime: data.returnEndAt,
                  destinationDate: data.returnDate,
                  nextDay: data.returnNextDay,
                  totalScale: data.returnTotalScales,
                  totalConnection: data.returnTotalConnections,
                  destionationScale: '',
                  originScale: '',
                  connectionAirpoirt: '',
                }}
                prices={{
                  adt: Number(data.minPriceAdt),
                  chd: Number(data.minPriceChd),
                  inf: Number(data.minPriceInf),
                  taxes: Number(data.departureTax),
                }}
                quantities={{
                  adt: Number(adultPassengers),
                  chd: Number(childrenPassengers),
                  inf: Number(babyPassengers),
                }}
                onClick={() =>
                  checkToday(data.departureDate) && validateSeat(data.charterId)
                }
                {...data}
              />
            ))}
            {page <= totalPages && !(flights.length <= 0) && (
              <div className="flex w-full justify-center">
                <ButtonV2 link onClick={loadMoreFlights} loading={loading}>
                  <ButtonV2.Text>Ver mais resultados</ButtonV2.Text>
                </ButtonV2>
              </div>
            )}
          </div>

          {extraFlights?.length > 0 && (
            <Fragment>
              <h3 className="mb-5 mt-8 text-heading-3 text-neutral-800">
                Confira os fretamentos que encontramos para você
              </h3>
              <div className="flex flex-col gap-4">
                {extraFlights?.map((data, index) => (
                  <BookingRouteCard
                    key={index}
                    baggage={data.departureBaggage}
                    index={index}
                    airports={dataBaseAeroportos}
                    departure={{
                      company: data.departureAirline,
                      operator: data.departureOperator,
                      flight: data.departureFlight,
                      companyLogo: data.departureAirlineLogo,
                      origin: data.originAirport,
                      originTime: data.departureStartAt,
                      originDate: data.departureDate,
                      destination: data.destinationAirport,
                      destinationTime: data.departureEndAt,
                      destinationDate: data.departureDate,
                      nextDay: data.departureNextDay,
                      totalScale: data.departureTotalScales,
                      totalConnection: data.departureTotalConnections,
                      destionationScale: '',
                      originScale: '',
                      connectionAirpoirt: '',
                    }}
                    return={{
                      company: data.returnAirline,
                      operator: data.returnOperator,
                      flight: data.returnFlight,
                      companyLogo: data.returnAirlineLogo,
                      origin: data.destinationAirport,
                      originTime: data.returnStartAt,
                      originDate: data.returnDate,
                      destination: data.originAirport,
                      destinationTime: data.returnEndAt,
                      destinationDate: data.returnDate,
                      nextDay: data.returnNextDay,
                      totalScale: data.returnTotalScales,
                      totalConnection: data.returnTotalConnections,
                      destionationScale: '',
                      originScale: '',
                      connectionAirpoirt: '',
                    }}
                    prices={{
                      adt: Number(data.minPriceAdt),
                      chd: Number(data.minPriceChd),
                      inf: Number(data.minPriceInf),
                      taxes: Number(data.departureTax),
                    }}
                    quantities={{
                      adt: Number(adultPassengers),
                      chd: Number(childrenPassengers),
                      inf: Number(babyPassengers),
                    }}
                    onClick={() =>
                      checkToday(data.departureDate) &&
                      validateSeat(data.charterId)
                    }
                    {...data}
                  />
                ))}
              </div>
              {pageExtraFlights <= totalPagesExtraFlights &&
                extraFlights?.length > 0 && (
                  <div className="flex w-full justify-center">
                    <ButtonV2
                      link
                      onClick={() => fetchExtraFlights(pageExtraFlights + 1)}
                      loading={loadingExtraFlights}
                    >
                      <ButtonV2.Text>Ver mais resultados</ButtonV2.Text>
                    </ButtonV2>
                  </div>
                )}
            </Fragment>
          )}
        </div>
      </div>
    </>
  );
}
