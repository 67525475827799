/* eslint-disable react/display-name */
import React, { forwardRef } from 'react';
import { BiX } from 'react-icons/bi';
import PropTypes from 'prop-types';
import { tv } from 'tailwind-variants';
const modalBodyStyle = tv({
  base: 'z-50 flex h-max flex-col items-center justify-center gap-4 rounded-md bg-white px-[30px] py-[30px] shadow-md',
  variants: {
    size: {
      small: 'w-1/3',
      medium: 'w-1/2',
      big: 'w-3/4',
      default: 'w-2/3',
    },
  },
  defaultVariants: {
    size: 'default',
  },
});

const Modal = forwardRef(
  ({ open, handleClose, children, className, size }, ref) => {
    const handleContentClick = (event) => {
      event.stopPropagation();
    };

    return (
      <dialog
        ref={ref}
        open={open}
        className="transition-transform duration-300"
      >
        <div
          onClick={handleClose}
          className="fixed left-0 top-0 z-[3] flex h-screen w-screen items-center justify-center bg-slate-600/30"
        >
          <div
            className={modalBodyStyle({ className, size })}
            onClick={handleContentClick}
          >
            {children}
          </div>
        </div>
      </dialog>
    );
  },
);

Modal.Header = ({ title, description, handleClose }) => {
  return (
    <div className="flex w-full justify-between border-x-0 border-b border-t-0 border-solid border-neutral-300">
      <div>
        <h2 className="mb-1.5 text-heading-1 text-xl font-semibold text-primary">
          {title}
        </h2>
        {description && (
          <h4 className="mb-4 text-heading-4"> {description} </h4>
        )}
      </div>
      <BiX
        size={27}
        onClick={handleClose}
        className="cursor-pointer hover:text-secondary"
      />
    </div>
  );
};

Modal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
};

export { Modal };
