import { Modal } from '@components/modal';
import { BiCalendar } from 'react-icons/bi';
import { useExternalBookings } from '@stores/external-bookings';
import { Button } from '@components/button';
import { useEffect, useState } from 'react';
import { differenceInDays, lightFormat } from 'date-fns';
import { formatCurrency } from '@system/utils';
import LoadingAnimation from '@components/LoadingAnimation';

export function ModalFinancialBookingData({
  openModal,
  onClose,
  reservationId,
}) {
  const [booking, setBooking] = useState({});

  const {
    loading,
    methods: { getExternalBooking },
  } = useExternalBookings();

  useEffect(() => {
    async function fetchData() {
      const data = await getExternalBooking(reservationId);

      setBooking({
        ...data,
        checkinDate: lightFormat(new Date(data?.checkinDate), 'dd/MM/yy'),
        checkinHour: lightFormat(new Date(data?.checkinDate), 'HH:mm'),
        checkoutDate: lightFormat(new Date(data?.checkoutDate), 'dd/MM/yy'),
        checkoutHour: lightFormat(new Date(data?.checkoutDate), 'HH:mm'),
        totalPassengers: data?.passengers?.length,
        totalAdults: data?.passengers?.filter(
          (passenger) => !!passenger.age && passenger.age >= 12,
        )?.length,
        totalChilds: data?.passengers?.filter(
          (passenger) =>
            !!passenger.age && passenger.age >= 2 && passenger.age <= 11,
        )?.length,
        totalBabies: data?.passengers?.filter(
          (passenger) =>
            !!passenger.age && passenger.age >= 0 && passenger.age < 2,
        )?.length,
        days: differenceInDays(data?.checkoutDate, data?.checkinDate),
      });
    }
    fetchData();
  }, [openModal]);

  return (
    <>
      {loading && (
        <div className="flex items-center">
          <LoadingAnimation />
        </div>
      )}
      {!loading && (
        <Modal
          open={openModal}
          handleClose={onClose}
          className="w-11/12 max-w-7xl text-neutral-800 md:mx-auto large:w-9/12"
        >
          <>
            <Modal.Header
              title={`Reserva ${reservationId}`}
              description={`${booking.days} noites, ${booking.totalPassengers} passageiro${booking.totalPassengers > 1 ? 's' : ''}`}
              handleClose={onClose}
            />
            <div className="flex w-full flex-col self-start">
              <div className="flex flex-row gap-x-5">
                <div className="flex items-center">
                  <BiCalendar size={20} />
                  <p className="m-0">
                    <span className="ml-1.5 font-semibold">Checkin: </span>
                    {booking?.checkinDate}, as {booking?.checkinHour}
                  </p>
                </div>
                <div className="flex items-center">
                  <BiCalendar size={20} />
                  <p className="m-0">
                    <span className="ml-1.5 font-semibold">Checkout: </span>
                    {booking?.checkoutDate}, as {booking?.checkinHour}
                  </p>
                </div>
              </div>
              <p className="mt-5">
                <span className="font-semibold">Hóspedes: </span>
                {booking?.totalAdults > 0
                  ? `${booking?.totalAdults} adulto${booking?.totalAdults > 1 ? 's' : ''}`
                  : ''}
                {booking?.totalChilds > 0
                  ? `,${booking?.totalChilds} criança${booking?.totalChilds > 1 ? 's' : ''}`
                  : ''}
                {booking?.totalBabies > 0
                  ? `,${booking?.totalBabies} bebê${booking?.totalBabies > 1 ? 's' : ''}`
                  : ''}
                {booking?.totalAdults == 0 &&
                booking.totalChilds == 0 &&
                booking.totalBabies == 0
                  ? '-'
                  : ''}
              </p>
              <p className="m3">
                <span className="font-semibold">Acomodação:</span>{' '}
                {booking?.accommodation}
              </p>
              <p className="border-0 border-b border-solid border-gray-200 pb-5">
                <span className="font-semibold">Regime:</span>{' '}
                {booking?.mealPlan}
              </p>

              <p className="font-semibold text-primary">Hóspedes</p>

              <div className="w-full rounded-md border border-solid border-gray-200 p-2.5">
                <table className="w-full text-start">
                  <thead className="border-0 border-b border-solid border-primary">
                    <tr>
                      <td>
                        <h3 className="p-2 text-heading-3 text-primary">
                          {' '}
                          Nome{' '}
                        </h3>
                      </td>
                      <td>
                        <h3 className="p-2 text-heading-3 text-primary">
                          {' '}
                          Idade{' '}
                        </h3>
                      </td>
                      <td>
                        <h3 className="p-2 text-heading-3 text-primary">
                          {' '}
                          CPF{' '}
                        </h3>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {booking?.passengers?.map((passenger, index) => (
                      <tr
                        key="1"
                        className={
                          booking?.passengers?.length - 1 !== index
                            ? `border-0 border-b border-solid border-gray-200`
                            : ''
                        }
                      >
                        <td key="name" className="px-2 py-2.5">
                          {passenger?.name ?? '-'}
                        </td>
                        <td key="age" className="px-2 py-2.5">
                          {passenger?.age ?? '-'}
                        </td>
                        <td key="doc" className="px-2 py-2.5">
                          {passenger?.document ?? '-'}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              <p className="m-0 py-5 font-semibold text-primary">
                Detalhes do preço
              </p>
              <div className="w-full rounded-md border border-solid border-gray-200 p-5">
                {booking?.status === 'completed' && (
                  <>
                    <p className="mb-4 text-sm">
                      Valor total da reserva:{' '}
                      <span className="font-semibold text-price">
                        {' '}
                        {formatCurrency(booking?.totalValue)}{' '}
                      </span>{' '}
                    </p>
                    <p className="m-0 mb-4 border-0 border-b border-solid border-neutral-600 pb-4 text-sm">
                      Valor da taxa de antecipação:{' '}
                      <span className="font-semibold text-price">
                        {' '}
                        {formatCurrency(booking?.totalAntecipationDiscount)}
                      </span>{' '}
                    </p>
                  </>
                )}
                <p className="m-0 text-xl font-semibold">
                  Valor total:{' '}
                  <span className="text-price">
                    {booking?.status === 'completed'
                      ? formatCurrency(booking?.totalReceivedValue)
                      : formatCurrency(booking?.totalValue)}
                  </span>
                </p>
                {booking?.status === 'completed' && (
                  <p className="m-0 mt-3 text-sm">
                    Valor total da reserva:{' '}
                    {lightFormat(
                      new Date(booking?.antecipationDate),
                      'dd/MM/yy',
                    )}
                  </p>
                )}
              </div>
            </div>

            <div className="mt-5 flex items-center">
              <Button variant="ghost" label="Fechar" onClick={onClose} />
            </div>
          </>
        </Modal>
      )}
    </>
  );
}
