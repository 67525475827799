import { Button } from '@components/button';
import { formatCurrency } from '@system/utils';
import { Modal } from '.';

export function ModalCancelChangeRules({
  isOpen,
  onClose,
  changeRules,
  cancelRules,
}) {
  function cancelRulesMessage() {
    if (!cancelRules?.cancelWithRefund && cancelRules?.cancelWithCost) {
      return (
        <>
          Possível cancelar, com custo adicional de{' '}
          {cancelRules?.cancelCostPercentage}% da terifa.
        </>
      );
    }

    if (!cancelRules?.cancelWithRefund && !cancelRules?.cancelWithCost) {
      return <>Possível cancelar, sem reembolso e custo adicional.</>;
    }

    if (cancelRules?.cancelWithRefund && !cancelRules?.refundWithDeadline) {
      return (
        <>
          Possível cancelar, com {cancelRules?.refundPercentage}% de reembolso
          da tarifa.
        </>
      );
    }

    if (cancelRules?.cancelWithRefund && cancelRules?.refundWithDeadline) {
      return (
        <>
          Possível cancelar, com {cancelRules?.refundPercentage}% de reembolso
          da tarifa, até {cancelRules?.refundDaysLimit} {''}
          dias antes da data de partida. Após {
            cancelRules?.refundDaysLimit
          }{' '}
          dias sera reembolsado somente {cancelRules?.refundPercentageDeadline}%{' '}
          da tarifa
        </>
      );
    }
  }

  return (
    <Modal open={isOpen} size={'big'} handleClose={onClose}>
      <Modal.Header
        title="Regras de alteração e cancelamento"
        handleClose={onClose}
      />
      <div className="w-[100%] rounded-[8px] border border-solid border-[#DFE1E7] p-5 text-sm text-neutral-800">
        {changeRules && (
          <div>
            <p className="mb-0 font-semibold">Alterações:</p>
            {changeRules?.changeWithCost && (
              <p className="font-normal">
                Possivel alterar, com custo de
                {formatCurrency(changeRules?.changeCostValue)} possível
                diferença da tarifa
              </p>
            )}
            {!changeRules?.changeWithCost && (
              <p className="font-normal">
                Possível alterar, sem custo adicional + possível diferença da
                tarifa, até {changeRules?.changeLimitDays} dias antes da data de
                partida. Após {changeRules?.changeLimitDays} dias, com custo de
                {formatCurrency(changeRules?.changeCostValue)}+ possível
                diferença da tarifa.
              </p>
            )}
          </div>
        )}
        {cancelRules && (
          <div>
            <p className="mb-0 font-semibold">Cancelamento:</p>
            <p className="font-normal">{cancelRulesMessage()}</p>
          </div>
        )}
      </div>
      <div className="flex w-[100%] justify-center">
        <Button onClick={onClose} variant="ghost" label="Fechar" />
      </div>
    </Modal>
  );
}
