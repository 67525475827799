import { useEffect, useMemo, useState } from 'react';
import { GoAlertFill } from 'react-icons/go';
import { IoAirplaneOutline } from 'react-icons/io5';
import { useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import BreadCrumbs from '@components/breadcrumbs';
import { Button } from '@components/button';
import { DropdownListRoutes } from '@components/dropdown-list-routes';
import { InputText, InputDate, InputRadio } from '@components/input';
import IntlCurrencyInput from '@components/intl-currency-input';
import { Form } from '@components/new-components/form';
import { Modal } from '@components/modal';
import { RouteCard } from '@components/route-card';
import { Select } from '@components/select';
import { Switch } from '@components/switch';
import Tag from '@components/Tag';
import api from '@services/api';
import { useAirports } from '@stores/airports';
import { toaster } from '@system/utils/toaster';
import LoadingAnimation from '../components/LoadingAnimation';
import { PagePath } from '../pages-config';

export default function AereoSaidasEditar() {
  const currencyConfig = {
    locale: 'pt-BR',
    formats: {
      number: {
        BRL: {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        },
      },
    },
  };

  const { methods } = useAirports();
  const navigate = useNavigate();
  const { id } = useParams();

  const [errors, setErrors] = useState({});

  const [loading, setLoading] = useState(false);
  const [dataIda, setDataIda] = useState('');
  const [dataVolta, setDataVolta] = useState('');
  const [contratante, setContratante] = useState('');
  const [dataBaseRotasOrigem, setDataBaseRotasOrigem] = useState([]);
  const [dataFilterBaseRotasOrigem, setDataFilterBaseRotasOrigem] = useState(
    [],
  );
  const [ida, setIda] = useState('');
  const [volta, setVolta] = useState('');
  const [primeiraclasseNorma, setPrimeiraclasseNorma] = useState('');
  const [premiumNorma, setPremiumNorma] = useState('');
  const [executivaNorma, setExecutivaNorma] = useState('');

  const [quantidadeBagagem, setQuantidadeBagagem] = useState(0);
  const [pesoBagagem, setPesoBagagem] = useState('');

  const [departureOrigin, setDepartureOrigin] = useState(null);
  const [returnOrigin, setReturnOrigin] = useState(null);
  const [active, setActive] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);

  const [canChange, setCanChange] = useState('');
  const [withCost, setWithCost] = useState('');
  const [changeCost, setChangeCost] = useState();
  const [limitDay, setLimitDay] = useState();

  const [canCancel, setCanCancel] = useState('');
  const [withRefund, setWithRefund] = useState('');
  const [withAditionalCost, setWithAditionalCost] = useState('');
  const [withDueDate, setWithDueDate] = useState('');
  const [cancelRefundPercentageAfter, setCancelRefundPercentageAfter] =
    useState();
  const [cancelLimitDay, setCancelLimitDay] = useState();
  const [cancelCostPercentage, setCancelCostPercentage] = useState();
  const [cancelRefundPercentage, setCancelRefundPercentage] = useState();

  const selectedDepartureDate = dayjs(dataIda).add('1', 'day');
  const selectedReturnDate = dayjs(dataVolta);

  const [isOnlyOneExcerpt, setIsOnlyOneExcerpt] = useState(false);

  useEffect(() => {
    setLoading(true);

    let queryVoo = `
    SELECT 
        r1.voo AS r1voo, 
        r2.voo AS r2voo, 
        r1.operadopor AS r1operadopor, 
        r2.operadopor AS r2operadopor, 
        vpcharter_fretamento.bagagem AS bagagem, 
        vpcharter_fretamento.ativo AS ativo, 
        vpcharter_fretamento.primeiraclasse_frete, 
        vpcharter_fretamento.premium_frete, 
        vpcharter_fretamento.executiva_frete, 
        vpcharter_fretamento.economica_frete, 
        vpcharter_fretamento.id AS id_fretamento, 
        vpcharter_fretamento.data_frete, 
        c1.nome AS r1cia, 
        c1.logo AS r1logo, 
        r1.voo AS r1voo, 
        r1.origem AS r1origem, 
        r1.outrodia AS r1outrodia, 
        r2.outrodia AS r2outrodia, 
        r1.saida AS r1saida, 
        r1.destino AS r1destino, 
        r1.chegada AS r1chegada, 
        vpcharter_fretamento.rota_ida, 
        vpcharter_fretamento.rota_volta, 
        vpcharter_fretamento.date_volta, 
        c2.nome AS r2cia, 
        c2.logo AS r2logo, 
        r2.voo AS r2voo, 
        r2.origem AS r2origem, 
        r2.saida AS r2saida, 
        r2.destino AS r2destino, 
        r2.chegada AS r2chegada, 
        vpcharter_fretamento.contratante, 
        vpcharter_fretamento.cancelamento,  
        vpcharter_contratante.razaosocial,

        r1.escala AS r1escala,
        r1.conexao AS r1conexao,
        r2.escala AS r2escala,
        r2.conexao AS r2conexao,
        (
          SELECT vpcharter_escala.destino
          FROM vpcharter_escala
          WHERE vpcharter_escala.rota = r2.id
          ORDER BY vpcharter_escala.id
          LIMIT 1
      ) AS r2escaladestino,
      (
          SELECT vpcharter_escala.origem
          FROM vpcharter_escala
          WHERE vpcharter_escala.rota = r2.id
          ORDER BY vpcharter_escala.id
          LIMIT 1
      ) AS r2escalaorigem,
      (
          SELECT vpcharter_escala.destino
          FROM vpcharter_escala
          WHERE vpcharter_escala.rota = r1.id
          ORDER BY vpcharter_escala.id
          LIMIT 1
      ) AS r1escaladestino,
      (
          SELECT vpcharter_escala.origem
          FROM vpcharter_escala
          WHERE vpcharter_escala.rota = r1.id
          ORDER BY vpcharter_escala.id
          LIMIT 1
      ) AS r1escalaorigem,
      (
          SELECT COUNT(id) AS total
          FROM vpcharter_conexao
          WHERE vpcharter_conexao.rota = r1.id
      ) AS r1totalconexao,
      (
          SELECT COUNT(id) AS total
          FROM vpcharter_escala
          WHERE vpcharter_escala.rota = r1.id
      ) AS r1totalescala,
      (
          SELECT COUNT(id) AS total
          FROM vpcharter_escala
          WHERE vpcharter_escala.rota = r2.id
      ) AS r2totalescala,
      (
          SELECT vpcharter_conexao.aeroporto
          FROM vpcharter_conexao
          WHERE vpcharter_conexao.rota = r1.id
          ORDER BY vpcharter_conexao.id
          LIMIT 1
      ) AS r1conexaoaeroporto,
      (
          SELECT vpcharter_conexao.aeroporto
          FROM vpcharter_conexao
          WHERE vpcharter_conexao.rota = r2.id
          ORDER BY vpcharter_conexao.id
          LIMIT 1
      ) AS r2conexaoaeroporto,
      (
          SELECT COUNT(id) AS total
          FROM vpcharter_conexao
          WHERE vpcharter_conexao.rota = r2.id
      ) AS r2totalconexao
        
    FROM 
        vpcharter_fretamento 
    INNER JOIN 
        vpcharter_contratante 
        ON vpcharter_fretamento.contratante = vpcharter_contratante.id 
    INNER JOIN 
        vpcharter_rotas r1 
        ON r1.id = vpcharter_fretamento.rota_ida 
    INNER JOIN 
        vpcharter_frota f1 
        ON f1.id = r1.aeronave 
    INNER JOIN 
        vpcharter_companhia c1 
        ON c1.id = f1.companhia 
    LEFT JOIN 
        vpcharter_rotas r2 
        ON r2.id = vpcharter_fretamento.rota_volta 
    LEFT JOIN 
        vpcharter_frota f2 
        ON f2.id = r2.aeronave 
    LEFT JOIN 
        vpcharter_companhia c2 
        ON c2.id = f2.companhia 
    WHERE 
        vpcharter_fretamento.id = '${id}' 
    LIMIT 1
`;

    const formDataGetVoo = new FormData();
    formDataGetVoo.append('query', queryVoo);
    api
      .post('/query', formDataGetVoo)
      .then((response) => {
        if (response.data.length > 0) {
          setDataIda(response.data[0]['data_frete']);
          setDataVolta(response.data[0]['date_volta']);
          setPrimeiraclasseNorma(response.data[0]['primeiraclasse_frete']);
          setPremiumNorma(response.data[0]['premium_frete']);
          setExecutivaNorma(response.data[0]['executiva_frete']);
          setDataIda(response.data[0]['data_frete']);
          setIda(response.data[0]['rota_ida']);
          setVolta(response.data[0]['rota_volta']);
          setActive(Boolean(response.data[0]['ativo'] === 1));
          setContratante(response.data[0]['contratante']);
          const bagagem = response.data[0]['bagagem']?.split('-');
          setQuantidadeBagagem(bagagem[0]);
          setPesoBagagem(bagagem[1] ?? '');
          let rota_volta = response.data[0]['rota_volta'] ?? null;
          setIsOnlyOneExcerpt(rota_volta ? true : false);
        }
      })
      .catch((error) => console.log(error));

    const queryRoutes = `
      SELECT 
          r.id AS id_rota,
          r.outrodia,
          c.nome AS nomeCia,
          c.logo,
          f.aeronave,
          ft.id AS idAeronave,
          r.origem,
          a.nome,
          a.cidade,
          a.uf,
          r.destino,
          r.saida,
          r.duracao,
          r.chegada,
          r.voo,
          r.escala,
          r.conexao,
          r.operadopor
      FROM 
          vpcharter_rotas r
      INNER JOIN 
          vpcharter_frota ft ON ft.id = r.aeronave
      INNER JOIN 
          vpcharter_companhia c ON c.id = ft.companhia
      INNER JOIN 
          vpcharter_fabricante f ON f.id = ft.fabricante
      INNER JOIN 
          vpcharter_aeroportos a ON a.iata = r.origem
      WHERE 
          r.ativo = 1;
    `;

    const formDataGetRoutes = new FormData();
    formDataGetRoutes.append('query', queryRoutes);
    api
      .post('/query', formDataGetRoutes)
      .then((response) => {
        if (response.data.length > 0) {
          setDataBaseRotasOrigem(response.data);
          setDataFilterBaseRotasOrigem(response.data);
        }
      })
      .catch((error) => alert(error))
      .finally(() => setLoading(false));

    methods.getAirports();
  }, []);

  const handleCancelRefundPercentageAfter = (e) => {
    const value = formatPercentage(e.target.value);
    if (value) {
      setCancelRefundPercentageAfter(value);
    } else {
      setCancelRefundPercentageAfter(cancelRefundPercentageAfter);
    }
    return;
  };

  const handleCancelCostPercentage = (e) => {
    const value = formatPercentage(e.target.value);
    if (value) {
      setCancelCostPercentage(value);
    } else {
      setCancelCostPercentage(cancelCostPercentage);
    }
    return;
  };

  const formatPercentage = (v) => {
    if (!v.includes('%')) {
      v = v.slice(0, -1);
    }
    const value = v.replace(/\D/g, '');
    if (value.length < 7) {
      return `${value / 100}%`;
    }
    return 0;
  };
  const handleCancelLimitDay = (e) => {
    const value = e.target.value.replace(/\D/g, '');
    setCancelLimitDay(value);
  };
  const handleCancelRefundPercentage = (e) => {
    const value = formatPercentage(e.target.value);
    if (value) {
      setCancelRefundPercentage(value);
    } else {
      setCancelRefundPercentage(cancelRefundPercentage);
    }
    return value;
  };

  const handleChangeCost = (e) => {
    const value = e.target.value.replace(/\D/g, '');
    setChangeCost(value / 100);
  };

  const handleLimitDay = (e) => {
    const value = e.target.value.replace(/\D/g, '');
    setLimitDay(value);
    return value;
  };

  function validaForm(e) {
    e.preventDefault();
    setLoading(true);

    if (selectedDepartureDate <= today) {
      setLoading(false);
      return toaster(
        'warning',
        'Por favor, selecione uma data de ida posterior ou igual a hoje.',
      );
    } else if (new Date(selectedReturnDate) < selectedDepartureDate) {
      setLoading(false);
      return toaster(
        'warning',
        'A data de volta não pode ser menor que a data de ida.',
      );
    }

    let errors = {};
    if (canChange == '' || (canChange == 'Y' && withCost == '')) {
      errors.change = {
        message: 'Campo obrigatório',
      };
    } else {
      if (canChange == 'Y') {
        if (
          (withCost == 'Y' && !changeCost) ||
          (withCost == 'N' && (!changeCost || !limitDay))
        ) {
          errors.change = {
            message: 'Campo obrigatório',
          };
        }
      }
    }

    if (canCancel == '') {
      errors.cancel = {
        message: 'Campo obrigatório',
      };
    } else if (canCancel == 'Y' && withRefund == '') {
      errors.cancel = {
        message: 'Campo obrigatório',
      };
    } else if (
      canCancel == 'Y' &&
      ((withRefund == 'Y' && withDueDate == '') ||
        (withRefund == 'N' && withAditionalCost == ''))
    ) {
      errors.cancel = {
        message: 'Campo obrigatório',
      };
    } else {
      if (canCancel == 'Y') {
        if (
          (withAditionalCost == 'Y' && !cancelCostPercentage) ||
          (withRefund == 'Y' &&
            withDueDate == 'Y' &&
            (!cancelRefundPercentage ||
              !cancelLimitDay ||
              !cancelRefundPercentageAfter)) ||
          (withRefund == 'Y' && withDueDate == 'N' && !cancelRefundPercentage)
        ) {
          errors.cancel = {
            message: 'Campo obrigatório',
          };
        }
      }
    }

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }

    let bagagem = `${quantidadeBagagem}-${pesoBagagem}`;

    let query = `
        UPDATE \`vpcharter_fretamento\`
        SET
            \`contratante\` = '${contratante}',
            \`data_frete\` = '${dataIda}',
            ${dataVolta !== null ? `\`date_volta\` = '${dataVolta}',` : ''}
            \`custo\` = '0',
            \`primeiraclasse_frete\` = '${primeiraclasseNorma}',
            \`premium_frete\` = '${premiumNorma}',
            \`executiva_frete\` = '${executivaNorma}',
            \`bagagem\` = '${bagagem}',
            \`ativo\` = '${active ? 1 : 0}',
            \`rota_ida\` = '${ida}',
            ${volta !== null ? `\`rota_volta\` = '${volta}'` : ''}
        WHERE \`vpcharter_fretamento\`.\`id\` = '${id}';
    `;
    query = query.replace(/,\s*WHERE/, ' WHERE');

    let fData = new FormData();
    fData.append('query', query);
    api
      .post('/query', fData)
      .then(async (response) => {
        if (response.status === 201) {
          if (canChange == 'Y') {
            const data = {
              changeWithCost: withCost == 'Y',
              changeCostValue: changeCost,
              changeLimitDays: limitDay,
            };
            await api.patch(`charters/${id}/change-rules`, data);
          }
          if (canCancel == 'Y') {
            const data = {
              cancelWithRefund: withRefund == 'Y',
              cancelWithCost: withCost === 'Y',
              refundWithDeadline: withDueDate == 'Y',
              refundPercentage: cancelRefundPercentage,
              refundDaysLimit: cancelLimitDay,
              cancelCostPercentage: cancelCostPercentage?.slice(0, -1),
              refundPercentageDeadline: cancelRefundPercentageAfter,
            };
            await api.patch(`charters/${id}/cancel-rules`, data);
          }

          toaster('success', 'Alterado com sucesso!');
          setLoading(false);
          setTimeout(
            () => navigate(PagePath.AereoSaidasPrecificar.replace(':id', id)),
            2000,
          );
        }
      })
      .catch((error) => console.log(error));
  }

  function alteraRotaIda() {
    setIda('');
  }
  function alteraRotaVolta() {
    setVolta('');
  }

  const today = new Date();

  const quantity = useMemo(
    () =>
      Array.from({ length: 3 }).map((_, index) => {
        return {
          value: index,
          label: index,
        };
      }),
    [],
  );

  const dataDepartureRoutes = dataFilterBaseRotasOrigem.find(
    (data) => data.id_rota === ida,
  );
  const dataReturnRoutes = dataFilterBaseRotasOrigem.find(
    (data) => data.id_rota === volta,
  );

  async function confirmInactivate() {
    setActive((prev) => !prev);
    setModalOpen((prev) => !prev);
  }

  function handleChangeStatus() {
    if (active) {
      return setModalOpen((prev) => !prev);
    }
    if (selectedDepartureDate <= today && !active) {
      return toaster(
        'error',
        'Não é possível ativar uma saída que possua data posterior ao dia atual',
      );
    }
    return setActive((prev) => !prev);
  }

  if (loading) {
    return <LoadingAnimation />;
  }

  return (
    <>
      <div className="flex flex-col gap-7 pb-6">
        <BreadCrumbs
          paths={[
            { label: 'Aéreo', link: PagePath.Aereo },
            { label: 'Saídas', link: PagePath.AereoSaidas },
            { label: 'Editar saída', link: PagePath.AereoSaidasEditar },
          ]}
        />
        <h1 className="m-0 text-heading-1 text-primary">Editar saída</h1>
      </div>

      <div>
        <form onSubmit={(e) => validaForm(e)}>
          <div className="flex flex-col gap-4 rounded-md bg-white p-[15px]">
            <div className="flex flex-col gap-4 rounded-md !border border-solid !border-neutral-300 p-4">
              <Tag
                value={
                  <div className="flex items-center gap-1">
                    <IoAirplaneOutline /> {volta ? 'Rota de ida' : 'Trecho'}
                  </div>
                }
              />

              <div className="col colSelect">
                {ida?.toString().trim().length > 0 ? (
                  <>
                    <label className="m-0 mb-2 text-sm font-medium text-neutral-800">
                      Rota
                    </label>
                    <RouteCard
                      data={dataDepartureRoutes}
                      onClick={alteraRotaIda}
                    />
                  </>
                ) : (
                  <DropdownListRoutes
                    placeholder="Digite a IATA, Cidade ou Estado"
                    dataSearch={dataBaseRotasOrigem}
                    value={departureOrigin}
                    defaultValue={ida}
                    label="Rota"
                    onChange={(value) => setDepartureOrigin(value)}
                    onSelectRoute={(route) => setIda(route.id_rota)}
                  />
                )}
              </div>

              <div className="col-3 w-48">
                <InputDate
                  label="Data de ida"
                  value={dataIda}
                  onChange={(event) => setDataIda(event.target.value)}
                  required
                />
              </div>
            </div>

            {isOnlyOneExcerpt && (
              <div className="flex flex-col gap-4 rounded-md !border border-solid !border-neutral-300 p-4">
                <Tag
                  value={
                    <div className="flex items-center gap-1">
                      <IoAirplaneOutline className="flip" /> Rota de volta
                    </div>
                  }
                />

                <div className="col colSelect">
                  {volta?.toString().trim().length > 0 ? (
                    <>
                      <label className="m-0 mb-2 text-sm font-medium text-neutral-800">
                        Rota
                      </label>
                      <RouteCard
                        data={dataReturnRoutes}
                        onClick={alteraRotaVolta}
                      />
                    </>
                  ) : (
                    <DropdownListRoutes
                      placeholder="Digite a IATA, Cidade ou Estado"
                      dataSearch={dataBaseRotasOrigem}
                      value={returnOrigin}
                      defaultValue={volta}
                      label="Rota"
                      onChange={(value) => setReturnOrigin(value)}
                      onSelectRoute={(route) => setVolta(route.id_rota)}
                    />
                  )}
                </div>

                <div className="col-3 w-48">
                  <InputDate
                    label="Data de volta"
                    type="date"
                    value={dataVolta}
                    onChange={(event) => setDataVolta(event.target.value)}
                    required
                  />
                </div>
              </div>
            )}

            <div className="flex flex-col gap-5 rounded-md !border border-solid !border-neutral-300 p-4">
              <h4 className="!text-heading-3 !text-primary">
                Bagagem para despachar
              </h4>
              <div className="flex flex-col items-center gap-4 md:flex-row">
                <Select
                  label="Quantidade"
                  value={quantidadeBagagem}
                  options={quantity}
                  onChange={(e) => setQuantidadeBagagem(e)}
                />
                <div className="col-md-6 col-12">
                  <InputText
                    label="Peso em kg"
                    value={pesoBagagem}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (/^[0-9,{1}]*$/.test(value)) {
                        setPesoBagagem(value);
                      }
                    }}
                    placeholder="Insira o peso da bagagem em kg"
                  />
                </div>
              </div>
            </div>

            <div className="flex w-full flex-col gap-5 rounded-md !border  !border-solid !border-neutral-300 p-[15px]">
              <span className="text-base font-semibold text-primary">
                Regras de alteração
              </span>
              <div className="flex w-full gap-4">
                <div className="w-[129px]">
                  <label className="mt-0">Poderá alterar?</label>
                </div>
                <div className="w-[70px]">
                  <InputRadio
                    label="Sim"
                    checked={canChange == 'Y'}
                    onChange={() => setCanChange('Y')}
                  />
                </div>
                <div className="w-[129px]">
                  <InputRadio
                    label="Não"
                    checked={canChange == 'N'}
                    onChange={() => setCanChange('N')}
                  />
                </div>
              </div>
              {canChange == 'Y' && (
                <div className="flex w-full gap-4">
                  <div className="w-[180px]">
                    <label className="mt-0">Com custo adicional?</label>
                  </div>
                  <div className="w-[70px]">
                    <InputRadio
                      label="Sim"
                      checked={withCost == 'Y'}
                      onChange={() => setWithCost('Y')}
                    />
                  </div>
                  <div className="w-[129px]">
                    <InputRadio
                      label="Não"
                      checked={withCost == 'N'}
                      onChange={() => setWithCost('N')}
                    />
                  </div>
                </div>
              )}
              {withCost == 'Y' && (
                <div className="flex w-full gap-4">
                  <div className="w-[70px]">
                    <label>Custo de</label>
                  </div>
                  <div className="w-[120px]">
                    <IntlCurrencyInput
                      currency="BRL"
                      config={currencyConfig}
                      placeholder="Valor em R$"
                      className="currency mt-1 text-sm"
                      value={changeCost}
                      onChange={handleChangeCost}
                    />
                  </div>
                </div>
              )}
              {withCost == 'N' && (
                <div className="flex w-full gap-4">
                  <div className="w-[30px]">
                    <label>Até</label>
                  </div>
                  <div className="w-[140px]">
                    <InputText
                      placeholder="Quantos dias?"
                      className="mt-1"
                      value={limitDay}
                      onChange={handleLimitDay}
                    />
                  </div>
                  <div className="w-[360px]">
                    <label>
                      dias antes da data de partida. Antes com custo de
                    </label>
                  </div>
                  <div className="w-[150px]">
                    <IntlCurrencyInput
                      currency="BRL"
                      config={currencyConfig}
                      placeholder="Valor em R$"
                      className="currency mt-1 text-sm"
                      value={changeCost}
                      onChange={handleChangeCost}
                    />
                  </div>
                </div>
              )}
              {errors?.change && (
                <div className="flex w-full gap-4">
                  <Form.Message error={errors?.change} />
                </div>
              )}
            </div>
            <div className="flex w-full flex-col gap-5 rounded-md !border  !border-solid !border-neutral-300 p-[15px]">
              <span className="text-base font-semibold text-primary">
                Regras de cancelamento
              </span>
              <div className="flex w-full gap-4">
                <div className="w-[150px]">
                  <label className="mt-0">Poderá cancelar?</label>
                </div>
                <div className="w-[70px]">
                  <InputRadio
                    label="Sim"
                    checked={canCancel == 'Y'}
                    onChange={() => setCanCancel('Y')}
                  />
                </div>
                <div className="w-[129px]">
                  <InputRadio
                    label="Não"
                    checked={canCancel == 'N'}
                    onChange={() => setCanCancel('N')}
                  />
                </div>
              </div>
              {canCancel == 'Y' && (
                <div className="flex w-full gap-4">
                  <div className="w-[150px]">
                    <label className="mt-0">Com reembolso?</label>
                  </div>
                  <div className="w-[70px]">
                    <InputRadio
                      label="Sim"
                      checked={withRefund == 'Y'}
                      onChange={() => setWithRefund('Y')}
                    />
                  </div>
                  <div className="w-[129px]">
                    <InputRadio
                      label="Não"
                      checked={withRefund == 'N'}
                      onChange={() => setWithRefund('N')}
                    />
                  </div>
                </div>
              )}
              {canCancel == 'Y' && withRefund == 'N' && (
                <div className="flex w-full gap-4">
                  <div className="w-[170px]">
                    <label className="mt-0">Com custo adicional?</label>
                  </div>
                  <div className="w-[70px]">
                    <InputRadio
                      label="Sim"
                      checked={withAditionalCost == 'Y'}
                      onChange={() => setWithAditionalCost('Y')}
                    />
                  </div>
                  <div className="w-[129px]">
                    <InputRadio
                      label="Não"
                      checked={withAditionalCost == 'N'}
                      onChange={() => setWithAditionalCost('N')}
                    />
                  </div>
                </div>
              )}
              {canCancel == 'Y' &&
                withRefund == 'N' &&
                withAditionalCost == 'Y' && (
                  <div className="flex w-full gap-4">
                    <div className="w-[170px]">
                      <label>Com custo adicional de</label>
                    </div>
                    <div className="w-[90px]">
                      <InputText
                        placeholder="Ex: 10%"
                        className="mt-1"
                        value={cancelCostPercentage}
                        onChange={handleCancelCostPercentage}
                      />
                    </div>
                    <div className="w-[150px]">
                      <label>do valor da tarifa.</label>
                    </div>
                  </div>
                )}

              {canCancel == 'Y' && withRefund == 'Y' && (
                <div className="flex w-full gap-4">
                  <div className="w-[150px]">
                    <label className="mt-0">Com data limite?</label>
                  </div>
                  <div className="w-[70px]">
                    <InputRadio
                      label="Sim"
                      checked={withDueDate == 'Y'}
                      onChange={() => setWithDueDate('Y')}
                    />
                  </div>
                  <div className="w-[129px]">
                    <InputRadio
                      label="Não"
                      checked={withDueDate == 'N'}
                      onChange={() => setWithDueDate('N')}
                    />
                  </div>
                </div>
              )}

              {canCancel == 'Y' && withRefund == 'Y' && withDueDate == 'N' && (
                <div className="flex w-full gap-4">
                  <div className="w-[115px]">
                    <label>Reembolso de</label>
                  </div>
                  <div className="w-[90px]">
                    <InputText
                      placeholder="Ex: 10%"
                      className="mt-1"
                      value={cancelRefundPercentage}
                      onChange={handleCancelRefundPercentage}
                    />
                  </div>
                  <div className="w-[150px]">
                    <label>do valor da tarifa.</label>
                  </div>
                </div>
              )}

              {canCancel == 'Y' && withRefund == 'Y' && withDueDate == 'Y' && (
                <>
                  <div className="flex w-full gap-4">
                    <div className="w-[115px]">
                      <label>Reembolso de</label>
                    </div>
                    <div className="w-[90px]">
                      <InputText
                        placeholder="Ex: 10%"
                        className="mt-1"
                        value={cancelRefundPercentage}
                        onChange={handleCancelRefundPercentage}
                      />
                    </div>
                    <div className="w-[150px]">
                      <label>do valor da tarifa até </label>
                    </div>
                    <div className="w-[140px]">
                      <InputText
                        placeholder="Quantos dias?"
                        className="mt-1"
                        value={cancelLimitDay}
                        onChange={handleCancelLimitDay}
                      />
                    </div>
                    <div className="w-[230px]">
                      <label>dias antes da data de partida. </label>
                    </div>
                  </div>
                  <div className="flex w-full gap-4">
                    <div className="w-[250px]">
                      <label>Após, será reembolsado soemente</label>
                    </div>
                    <div className="w-[90px]">
                      <InputText
                        placeholder="Ex: 10%"
                        className="mt-1"
                        value={cancelRefundPercentageAfter}
                        onChange={handleCancelRefundPercentageAfter}
                      />
                    </div>
                    <div className="w-[150px]">
                      <label>da tarifa. </label>
                    </div>
                  </div>
                </>
              )}
              {errors?.cancel && (
                <div className="flex w-full gap-4">
                  <Form.Message error={errors?.cancel} />
                </div>
              )}
            </div>

            <Switch
              label="Ativa"
              checked={active}
              onChange={handleChangeStatus}
            />
            <div className="flex w-full justify-end">
              <Button
                label="Salvar"
                className="w-max px-[75px]"
                type="submit"
              />
            </div>
          </div>
        </form>
      </div>
      <Modal open={modalOpen}>
        <div className="flex flex-col items-center justify-center px-[190px] py-[60px]">
          <GoAlertFill className="text-warning-500" size={38} />
          <span className="mt-3 text-center text-heading-1 text-warning-500">
            Tem certeza que deseja inativar essa saída?
          </span>
          <p className="mb-[30px] mt-[15px] text-center !text-heading-4 text-neutral-800">
            Ao inativar essa saída, ela será ocultada da listagem de
            disponibilidades de reservas.
          </p>
          <div className="mt-3 flex items-center justify-center gap-[30px]">
            <Button
              variant="ghost"
              label="Cancelar"
              onClick={() => setModalOpen((prev) => !prev)}
            />
            <Button label="Inativar saída" onClick={confirmInactivate} />
          </div>
        </div>
      </Modal>
    </>
  );
}
