import { useState, useEffect, Fragment, useRef, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import BreadCrumbs from '@components/breadcrumbs';
import { Button } from '@components/button';
import { SearchInput } from '@components/search-input';
import { Tab } from '@components/tab';
import api from '@services/api';
import { formatMonth } from '@system/utils/formatMonth';
import { Carousel } from '@trendyol-js/react-carousel';
import LoadingAnimation from '../../components/LoadingAnimation';
import { PagePath } from '../../pages-config';
import './AereoSaidas.scss';
import { FlightDetailsCard } from './components/FlightDetailsCard';

export default function AereoSaidas() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const [selectMonth, setSelectMonth] = useState('');

  const [currentYear, setCurrentYear] = useState('');
  const [currentIndex, setIndex] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');

  const [charters, setCharters] = useState({
    limit: 20,
    page: 1,
    result: [],
    total: 1,
    totalPages: 1,
  });
  const [airports, setAirports] = useState([]);

  useEffect(() => {
    let query = 'SELECT * FROM `vpcharter_aeroportos` ';
    let fData = new FormData();
    fData.append('query', query);

    api.get('/airports').then((response) => {
      setAirports(response.data);
    });

    let Xmas95 = new Date();
    let currentMonth = Xmas95.getMonth() + 1;

    for (let i = 1; i < 13; i++) {
      let idSelect = 'btnMonth' + [i];
      document.getElementById(idSelect).className = '';
    }

    setCurrentYear(Xmas95.getFullYear());
    let idSelect = 'btnMonth' + currentMonth;
    document.getElementById(idSelect).className = 'selectMonth';
    document.getElementById(idSelect + 'b').className = 'selectMonth';
    document.getElementById(idSelect + 'c').className = 'selectMonth';
    document.getElementById(idSelect + 'd').className = 'selectMonth';
    document.getElementById(idSelect + 'e').className = 'selectMonth';

    setSelectMonth(currentMonth);
    onInit(currentMonth);
  }, []);

  function onInit(month) {
    setLoading(true);

    let Xmas95 = new Date();
    let currentYear = Xmas95.getFullYear();
    let currentMonth = Xmas95.getMonth() + 1;
    if (month < currentMonth) {
      currentYear = currentYear + 1;
    }

    setCurrentYear(currentYear);

    let startMonth = currentYear + '-' + month + '-01';
    let numDias = new Date(currentYear, month, 0).getDate();
    let finalMonth = currentYear + '-' + month + '-' + numDias;

    api
      .get('/charters', {
        params: {
          outboundDate: startMonth,
          returnDate: finalMonth,
        },
      })
      .then((response) => {
        setCharters(response.data);
      })
      .catch((error) => alert(error))
      .finally(() => {
        setLoading(false);
      });
  }

  function registerNewDeparture() {
    navigate(PagePath.AereoSaidasCadastrar);
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' || event.key === 'Tab') {
      setSearchTerm(searchTermRef.current.value);
    }
  };

  function changeMonth(month) {
    // Limpa as classes de todos os botões
    for (let i = 1; i <= 12; i++) {
      for (let suffix of ['', 'b', 'c', 'd', 'e']) {
        document.getElementById(`btnMonth${i}${suffix}`).className = '';
      }
    }

    // Adiciona a classe de seleção para o mês especificado
    for (let suffix of ['', 'b', 'c', 'd', 'e']) {
      document.getElementById(`btnMonth${month}${suffix}`).className =
        'selectMonth';
    }

    setSelectMonth(month);
    onInit(month);
  }

  const handleChange = (newIndex) => setIndex(newIndex);

  const chartersFiltered = useMemo(() => {
    return charters.result.filter((data) => {
      return (
        data?.outboundRoute?.fleet?.airline?.name
          ?.toLowerCase()
          .includes(searchTerm?.toLowerCase()) ||
        data?.outboundRoute?.origin
          ?.toLowerCase()
          .includes(searchTerm?.toLowerCase()) ||
        data?.outboundRoute?.destination
          ?.toLowerCase()
          .includes(searchTerm?.toLowerCase()) ||
        String(data?.outboundRoute?.flight).includes(searchTerm) ||
        data?.outboundDate?.includes(searchTerm) ||
        data?.returnRoute?.fleet?.airline?.name
          ?.toLowerCase()
          .includes(searchTerm?.toLowerCase()) ||
        data?.returnRoute?.origin
          ?.toLowerCase()
          .includes(searchTerm?.toLowerCase()) ||
        data?.returnRoute?.destination
          ?.toLowerCase()
          .includes(searchTerm?.toLowerCase())
      );
    });
  }, [charters, searchTerm]);

  const filterQuantity = chartersFiltered.filter(
    (data) => data.status !== currentIndex,
  ).length;

  const searchTermRef = useRef();

  const handleSearch = () => {
    setSearchTerm(searchTermRef.current.value);
  };

  return (
    <div className="w-full">
      {loading && <LoadingAnimation />}
      <div className="flex flex-col">
        <BreadCrumbs
          paths={[
            { label: 'Aéreo', link: PagePath.Aereo },
            { label: 'Saídas', link: PagePath.AereoSaidas },
          ]}
        />
        <div className="mb-[25px] mt-[10px] flex w-full items-center justify-between">
          <h1 className="m-0 text-heading-1 text-primary">Saídas</h1>
          <Button
            label="Criar nova saída"
            className="w-max"
            onClick={registerNewDeparture}
          />
        </div>
      </div>

      <div className="w-full rounded-md bg-white p-4">
        <h3 className="!text-heading-3 !text-primary">
          Escolha o mês da data de saída
        </h3>
        <div className="w-full">
          <div className="monthCarousel carousel_1">
            <Carousel
              show={8.5}
              slide={2}
              infinite={false}
              swiping={true}
              responsive={true}
            >
              <button onClick={() => changeMonth('1')} id="btnMonth1">
                Janeiro
              </button>
              <button onClick={() => changeMonth('2')} id="btnMonth2">
                Fevereiro
              </button>
              <button onClick={() => changeMonth('3')} id="btnMonth3">
                Março
              </button>
              <button onClick={() => changeMonth('4')} id="btnMonth4">
                Abril
              </button>
              <button onClick={() => changeMonth('5')} id="btnMonth5">
                Maio
              </button>
              <button onClick={() => changeMonth('6')} id="btnMonth6">
                Junho
              </button>
              <button onClick={() => changeMonth('7')} id="btnMonth7">
                Julho
              </button>
              <button onClick={() => changeMonth('8')} id="btnMonth8">
                Agosto
              </button>
              <button onClick={() => changeMonth('9')} id="btnMonth9">
                Setembro
              </button>
              <button onClick={() => changeMonth('10')} id="btnMonth10">
                Outubro
              </button>
              <button onClick={() => changeMonth('11')} id="btnMonth11">
                Novembro
              </button>
              <button onClick={() => changeMonth('12')} id="btnMonth12">
                Dezembro
              </button>
            </Carousel>
          </div>

          <div className="monthCarousel carousel_2">
            <Carousel
              show={6.5}
              slide={2}
              infinite={false}
              swiping={true}
              responsive={true}
            >
              <button onClick={() => changeMonth('1')} id="btnMonth1b">
                Janeiro
              </button>
              <button onClick={() => changeMonth('2')} id="btnMonth2b">
                Fevereiro
              </button>
              <button onClick={() => changeMonth('3')} id="btnMonth3b">
                Março
              </button>
              <button onClick={() => changeMonth('4')} id="btnMonth4b">
                Abril
              </button>
              <button onClick={() => changeMonth('5')} id="btnMonth5b">
                Maio
              </button>
              <button onClick={() => changeMonth('6')} id="btnMonth6b">
                Junho
              </button>
              <button onClick={() => changeMonth('7')} id="btnMonth7b">
                Julho
              </button>
              <button onClick={() => changeMonth('8')} id="btnMonth8b">
                Agosto
              </button>
              <button onClick={() => changeMonth('9')} id="btnMonth9b">
                Setembro
              </button>
              <button onClick={() => changeMonth('10')} id="btnMonth10b">
                Outubro
              </button>
              <button onClick={() => changeMonth('11')} id="btnMonth11b">
                Novembro
              </button>
              <button onClick={() => changeMonth('12')} id="btnMonth12b">
                Dezembro
              </button>
            </Carousel>
          </div>

          <div className="monthCarousel carousel_3">
            <Carousel
              show={5.5}
              infinite={false}
              swiping={true}
              responsive={true}
            >
              <button onClick={() => changeMonth('1')} id="btnMonth1c">
                Janeiro
              </button>
              <button onClick={() => changeMonth('2')} id="btnMonth2c">
                Fevereiro
              </button>
              <button onClick={() => changeMonth('3')} id="btnMonth3c">
                Março
              </button>
              <button onClick={() => changeMonth('4')} id="btnMonth4c">
                Abril
              </button>
              <button onClick={() => changeMonth('5')} id="btnMonth5c">
                Maio
              </button>
              <button onClick={() => changeMonth('6')} id="btnMonth6c">
                Junho
              </button>
              <button onClick={() => changeMonth('7')} id="btnMonth7c">
                Julho
              </button>
              <button onClick={() => changeMonth('8')} id="btnMonth8c">
                Agosto
              </button>
              <button onClick={() => changeMonth('9')} id="btnMonth9c">
                Setembro
              </button>
              <button onClick={() => changeMonth('10')} id="btnMonth10c">
                Outubro
              </button>
              <button onClick={() => changeMonth('11')} id="btnMonth11c">
                Novembro
              </button>
              <button onClick={() => changeMonth('12')} id="btnMonth12c">
                Dezembro
              </button>
            </Carousel>
          </div>

          <div className="monthCarousel carousel_4">
            <Carousel
              show={4.5}
              infinite={false}
              swiping={true}
              responsive={true}
            >
              <button onClick={() => changeMonth('1')} id="btnMonth1d">
                Janeiro
              </button>
              <button onClick={() => changeMonth('2')} id="btnMonth2d">
                Fevereiro
              </button>
              <button onClick={() => changeMonth('3')} id="btnMonth3d">
                Março
              </button>
              <button onClick={() => changeMonth('4')} id="btnMonth4d">
                Abril
              </button>
              <button onClick={() => changeMonth('5')} id="btnMonth5d">
                Maio
              </button>
              <button onClick={() => changeMonth('6')} id="btnMonth6d">
                Junho
              </button>
              <button onClick={() => changeMonth('7')} id="btnMonth7d">
                Julho
              </button>
              <button onClick={() => changeMonth('8')} id="btnMonth8d">
                Agosto
              </button>
              <button onClick={() => changeMonth('9')} id="btnMonth9d">
                Setembro
              </button>
              <button onClick={() => changeMonth('10')} id="btnMonth10d">
                Outubro
              </button>
              <button onClick={() => changeMonth('11')} id="btnMonth11d">
                Novembro
              </button>
              <button onClick={() => changeMonth('12')} id="btnMonth12d">
                Dezembro
              </button>
            </Carousel>
          </div>

          <div className="monthCarousel carousel_5">
            <Carousel
              show={2}
              infinite={false}
              swiping={true}
              responsive={true}
            >
              <button onClick={() => changeMonth('1')} id="btnMonth1e">
                Janeiro
              </button>
              <button onClick={() => changeMonth('2')} id="btnMonth2e">
                Fevereiro
              </button>
              <button onClick={() => changeMonth('3')} id="btnMonth3e">
                Março
              </button>
              <button onClick={() => changeMonth('4')} id="btnMonth4e">
                Abril
              </button>
              <button onClick={() => changeMonth('5')} id="btnMonth5e">
                Maio
              </button>
              <button onClick={() => changeMonth('6')} id="btnMonth6e">
                Junho
              </button>
              <button onClick={() => changeMonth('7')} id="btnMonth7e">
                Julho
              </button>
              <button onClick={() => changeMonth('8')} id="btnMonth8e">
                Agosto
              </button>
              <button onClick={() => changeMonth('9')} id="btnMonth9e">
                Setembro
              </button>
              <button onClick={() => changeMonth('10')} id="btnMonth10e">
                Outubro
              </button>
              <button onClick={() => changeMonth('11')} id="btnMonth11e">
                Novembro
              </button>
              <button onClick={() => changeMonth('12')} id="btnMonth12e">
                Dezembro
              </button>
            </Carousel>
          </div>
        </div>

        <Fragment>
          <Tab value={currentIndex} onChange={handleChange}>
            <div className="mt-2 flex w-full flex-col rounded-md border border-solid !border-neutral-400">
              <Tab.Heads>
                <Tab.Item label="Ativas" index={0} />
                <Tab.Item label="Inativas" index={1} />
              </Tab.Heads>
              <Tab.ContentWrapper>
                <Tab.Content index={0}>
                  <div className="flex w-full flex-col gap-5">
                    <div className="grid w-full grid-cols-2 items-center self-end">
                      {chartersFiltered.length > 0 ? (
                        <span className="text-heading-3 text-primary">
                          {filterQuantity} resultados encontrados para{' '}
                          <span className="text-secondary">
                            {formatMonth(selectMonth)}
                          </span>
                        </span>
                      ) : (
                        <span className="text-heading-3 text-primary">
                          Não encontramos resultados para{' '}
                          <span className="text-secondary">
                            {formatMonth(selectMonth)}
                          </span>
                        </span>
                      )}
                      <div className="w-[328px] place-self-end">
                        <SearchInput
                          placeholder="Procurar por Origem ou Destino"
                          ref={searchTermRef}
                          onChange={(event) => {
                            if (event.target.value.trim().length === 0) {
                              onInit(selectMonth);
                              setSearchTerm('');
                            } else {
                              setSearchTerm(event.target.value);
                            }
                          }}
                          onKeyDown={handleKeyDown}
                          handleSearch={handleSearch}
                        />
                      </div>
                    </div>
                    {chartersFiltered
                      .filter((data) => data.status === 1)
                      .map((data) => (
                        <FlightDetailsCard
                          key={data.id}
                          id={data.id}
                          baggage={data.baggage}
                          airports={airports}
                          cancelRules={data?.cancelRules?.[0] ?? null}
                          changeRules={data?.changeRules?.[0] ?? null}
                          departureData={{
                            operator: data.outboundRoute?.fleet?.airline?.name,
                            flight: data?.outboundRoute?.flight,
                            companyLogo:
                              data.outboundRoute?.fleet?.airline?.logo,
                            origin: data?.outboundRoute?.origin,
                            originTime: data?.outboundRoute?.departure,
                            originDate: data?.outboundDate,
                            destination: data?.outboundRoute?.destination,
                            destinationTime: data?.outboundRoute?.arrival,
                            baggage: data?.outboundRoute?.baggage,
                            connections: data?.outboundRoute?.connections ?? [],
                            layovers: data?.outboundRoute?.layovers ?? [],
                          }}
                          returnData={{
                            operator: data.returnRoute?.fleet?.airline?.name,
                            flight: data?.returnRoute?.flight,
                            companyLogo: data.returnRoute?.fleet?.airline?.logo,
                            origin: data?.returnRoute?.origin,
                            originTime: data?.returnRoute?.departure,
                            originDate: data?.returnDate,
                            destination: data?.returnRoute?.destination,
                            destinationTime: data?.returnRoute?.arrival,
                            connections: data?.returnRoute?.connections ?? [],
                            layovers: data?.returnRoute?.layovers ?? [],
                          }}
                          showPriceButton
                          showEditButton
                        />
                      ))}
                  </div>
                </Tab.Content>
                <Tab.Content index={1}>
                  <div className="flex w-full flex-col gap-5">
                    <div className="grid w-full grid-cols-2 items-center">
                      {chartersFiltered.length > 0 ? (
                        <span className="text-heading-3 text-primary">
                          {filterQuantity} resultados encontrados para{' '}
                          <span className="text-secondary">
                            {formatMonth(selectMonth)}
                          </span>
                        </span>
                      ) : (
                        <span>
                          Não encontramos resultados para{' '}
                          {formatMonth(selectMonth)} de {currentYear}
                        </span>
                      )}

                      <div className="w-[328px] place-self-end">
                        <SearchInput
                          placeholder="Procurar por Origem ou Destino"
                          ref={searchTermRef}
                          onChange={(event) => {
                            if (event.target.value.trim().length === 0) {
                              onInit(selectMonth);
                              setSearchTerm('');
                            } else {
                              setSearchTerm(event.target.value);
                            }
                          }}
                          onKeyDown={handleKeyDown}
                          handleSearch={handleSearch}
                        />
                      </div>
                    </div>
                    {chartersFiltered
                      .filter((data) => data.status === 0)
                      .map((data) => (
                        <FlightDetailsCard
                          key={data.id}
                          id={data.id}
                          baggage={data.baggage}
                          airports={airports}
                          cancelRules={data?.cancelRules?.[0]}
                          changeRules={data?.changeRules?.[0]}
                          departureData={{
                            operator: data.outboundRoute?.fleet?.airline?.name,
                            flight: data?.outboundRoute?.flight,
                            companyLogo:
                              data.outboundRoute?.fleet?.airline?.logo,
                            origin: data?.outboundRoute?.origin,
                            originTime: data?.outboundRoute?.departure,
                            originDate: data?.outboundDate,
                            destination: data?.outboundRoute?.destination,
                            destinationTime: data?.outboundRoute?.arrival,
                            connections: data?.outboundRoute?.connections ?? [],
                            layovers: data?.outboundRoute?.layovers ?? [],
                          }}
                          returnData={{
                            operator: data.returnRoute?.fleet?.airline?.name,
                            flight: data?.returnRoute?.flight,
                            companyLogo: data.returnRoute?.fleet?.airline?.logo,
                            origin: data?.returnRoute?.origin,
                            originTime: data?.returnRoute?.departure,
                            originDate: data?.returnDate,
                            destination: data?.returnRoute?.destination,
                            destinationTime: data?.returnRoute?.arrival,
                            connections: data?.returnRoute?.connections ?? [],
                            layovers: data?.returnRoute?.layovers ?? [],
                          }}
                          showPriceButton
                          showEditButton
                        />
                      ))}
                  </div>
                </Tab.Content>
              </Tab.ContentWrapper>
            </div>
          </Tab>
        </Fragment>
      </div>
    </div>
  );
}
