import { useState, useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { GoAlertFill } from 'react-icons/go';
import { IoAirplaneOutline } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import BreadCrumbs from '@components/breadcrumbs';
import { Button } from '@components/button';
import { DropdownListRoutes } from '@components/dropdown-list-routes';
import { Form } from '@components/new-components/form';
import { InputText, InputDate, InputRadio } from '@components/input';
import IntlCurrencyInput from '@components/intl-currency-input';
import { Modal } from '@components/modal';
import { RouteCard } from '@components/route-card';
import { Select } from '@components/select';
import { Switch } from '@components/switch';
import { Tab } from '@components/tab';
import Tag from '@components/Tag';
import api from '@services/api';
import { useAirports } from '@stores/airports';
import { useAuthentication } from '@stores/authentication';
import { toaster } from '@system/utils/toaster';
import LoadingAnimation from '../components/LoadingAnimation';
import { PagePath } from '../pages-config';

export default function AereoSaidasCadastrar() {
  const currencyConfig = {
    locale: 'pt-BR',
    formats: {
      number: {
        BRL: {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        },
      },
    },
  };

  const { session } = useAuthentication();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [errors, setErrors] = useState({});

  const [canChange, setCanChange] = useState('');
  const [withCost, setWithCost] = useState('');
  const [changeCost, setChangeCost] = useState();
  const [limitDay, setLimitDay] = useState();

  const [canCancel, setCanCancel] = useState('');
  const [withRefund, setWithRefund] = useState('');
  const [withAditionalCost, setWithAditionalCost] = useState('');
  const [withDueDate, setWithDueDate] = useState('');
  const [cancelRefundPercentageAfter, setCancelRefundPercentageAfter] =
    useState();
  const [cancelLimitDay, setCancelLimitDay] = useState();
  const [cancelCostPercentage, setCancelCostPercentage] = useState();
  const [cancelRefundPercentage, setCancelRefundPercentage] = useState();

  const [dataBaseRotasOrigem, setDataBaseRotasOrigem] = useState([]);
  const { methods } = useAirports();

  async function getRoutesInformations() {
    setLoading(true);
    const queryRoutes = `
      SELECT 
          r.id AS id_rota,
          r.outrodia,
          c.nome AS nomeCia,
          c.logo,
          f.aeronave,
          ft.id AS idAeronave,
          r.origem,
          a.nome,
          a.cidade,
          a.uf,
          r.destino,
          r.saida,
          r.duracao,
          r.chegada,
          r.voo,
          r.escala,
          r.conexao,
          r.operadopor
      FROM 
          vpcharter_rotas r
      INNER JOIN 
          vpcharter_frota ft ON ft.id = r.aeronave
      INNER JOIN 
          vpcharter_companhia c ON c.id = ft.companhia
      INNER JOIN 
          vpcharter_fabricante f ON f.id = ft.fabricante
      INNER JOIN 
          vpcharter_aeroportos a ON a.iata = r.origem
      WHERE 
          r.ativo = 1;
    `;
    const formDataRoutes = new FormData();
    formDataRoutes.append('query', queryRoutes);

    try {
      const { data } = await api.post('/query', formDataRoutes);
      setDataBaseRotasOrigem(data);
    } catch (error) {
      toaster('error', 'Não foi possível carregar as rotas');
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getRoutesInformations();
    methods.getAirports();
  }, []);

  const today = new Date();

  const [departureOrigin, setDepartureOrigin] = useState('');
  const [returnOrigin, setReturnOrigin] = useState('');
  const [currentIndex, setIndex] = useState(0);
  const [modalOpen, setModalOpen] = useState({
    opened: false,
    isOnlyOneExcerpt: false,
  });

  const handleChange = (newIndex) => setIndex(newIndex);

  const { control, handleSubmit, setValue } = useForm({
    defaultValues: {
      quantity: 0,
      active: true,
    },
  });
  const {
    control: controlOnlyOneExcerpt,
    handleSubmit: handleSubmitOnlyOneExcerpt,
    setValue: setValueOnlyOneExcerpt,
  } = useForm({
    defaultValues: {
      quantityOnlyOneExcerpt: 0,
      activeOnlyOneExcerpt: true,
    },
  });

  const quantity = useMemo(
    () =>
      Array.from({ length: 3 }).map((_, index) => {
        return {
          value: index,
          label: index,
        };
      }),
    [],
  );

  const handleCancelRefundPercentageAfter = (e) => {
    const value = formatPercentage(e.target.value);
    if (value) {
      setCancelRefundPercentageAfter(value);
    } else {
      setCancelRefundPercentageAfter(cancelRefundPercentageAfter);
    }
    return;
  };

  const handleCancelCostPercentage = (e) => {
    const value = formatPercentage(e.target.value);
    if (value) {
      setCancelCostPercentage(value);
    } else {
      setCancelCostPercentage(cancelCostPercentage);
    }
    return;
  };

  const formatPercentage = (v) => {
    if (!v.includes('%')) {
      v = v.slice(0, -1);
    }
    const value = v.replace(/\D/g, '');
    if (value.length < 7) {
      return `${value / 100}%`;
    }
    return 0;
  };
  const handleCancelLimitDay = (e) => {
    const value = e.target.value.replace(/\D/g, '');
    setCancelLimitDay(value);
  };
  const handleCancelRefundPercentage = (e) => {
    const value = formatPercentage(e.target.value);
    if (value) {
      setCancelRefundPercentage(value);
    } else {
      setCancelRefundPercentage(cancelRefundPercentage);
    }
    return value;
  };

  const handleChangeCost = (e) => {
    const value = e.target.value.replace(/\D/g, '');
    setChangeCost(value / 100);
  };

  const handleLimitDay = (e) => {
    const value = e.target.value.replace(/\D/g, '');
    setLimitDay(value);
    return value;
  };

  function handleSubmitDepartureAndReturn(payload) {
    const selectedDepartureDate = dayjs(payload.departureDate);
    const selectedReturnDate = dayjs(payload.returnDate);

    if (selectedDepartureDate <= today) {
      return toaster(
        'warning',
        'Por favor, selecione uma data de ida posterior ou igual a hoje.',
      );
    } else if (new Date(selectedReturnDate) < selectedDepartureDate) {
      return toaster(
        'warning',
        'A data de volta não pode ser menor que a data de ida.',
      );
    }

    let errors = {};
    if (canChange == '' || (canChange == 'Y' && withCost == '')) {
      errors.change = {
        message: 'Campo obrigatório',
      };
    } else {
      if (canChange == 'Y') {
        if (
          (withCost == 'Y' && !changeCost) ||
          (withCost == 'N' && (!changeCost || !limitDay))
        ) {
          errors.change = {
            message: 'Campo obrigatório',
          };
        }
      }
    }

    if (canCancel == '') {
      errors.cancel = {
        message: 'Campo obrigatório',
      };
    } else if (canCancel == 'Y' && withRefund == '') {
      errors.cancel = {
        message: 'Campo obrigatório',
      };
    } else if (
      canCancel == 'Y' &&
      ((withRefund == 'Y' && withDueDate == '') ||
        (withRefund == 'N' && withAditionalCost == ''))
    ) {
      errors.cancel = {
        message: 'Campo obrigatório',
      };
    } else {
      if (canCancel == 'Y') {
        if (
          (withAditionalCost == 'Y' && !cancelCostPercentage) ||
          (withRefund == 'Y' &&
            withDueDate == 'Y' &&
            (!cancelRefundPercentage ||
              !cancelLimitDay ||
              !cancelRefundPercentageAfter)) ||
          (withRefund == 'Y' && withDueDate == 'N' && !cancelRefundPercentage)
        ) {
          errors.cancel = {
            message: 'Campo obrigatório',
          };
        }
      }
    }

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }

    let query = `
      INSERT INTO vpcharter_fretamento 
        (id, rota_ida, rota_volta, data_frete, date_volta, contratante, bagagem, ativo) 
      VALUES 
        (NULL, 
        '${payload.departureRoute.id_rota}', 
        '${payload.returnRoute.id_rota}', 
        '${payload.departureDate}', 
        '${payload.returnDate}', 
        '${session.agency.id}', 
        '${payload?.quantity}-${payload?.pound ?? ''}', 
        ${payload.active ? 1 : 0});
    `;

    let fData = new FormData();
    fData.append('query', query);

    api
      .post('/query', fData)
      .then((response) => {
        if (response.status === 201) {
          toaster('success', 'Saída cadastrada com sucesso!');
          let query = `
            SELECT * FROM vpcharter_fretamento 
            WHERE rota_ida = '${payload.departureRoute.id_rota}' 
              AND rota_volta = '${payload.returnRoute.id_rota}' 
              AND data_frete = '${payload.departureDate}' 
              AND date_volta = '${payload.returnDate}';
          `;
          let fData = new FormData();
          fData.append('query', query);
          api
            .post('/query', fData)
            .then(async (response) => {
              if (response.data.length > 0) {
                let idfret = response.data[0]['id'];
                if (canChange == 'Y') {
                  const data = {
                    changeWithCost: withCost == 'Y',
                    changeCostValue: changeCost,
                    changeLimitDays: limitDay,
                  };
                  await api.patch(`charters/${idfret}/change-rules`, data);
                }
                if (canCancel == 'Y') {
                  const data = {
                    cancelWithRefund: withRefund == 'Y',
                    cancelWithCost: withCost === 'Y',
                    refundWithDeadline: withDueDate == 'Y',
                    refundPercentage: cancelRefundPercentage,
                    refundDaysLimit: cancelLimitDay,
                    cancelCostPercentage: cancelCostPercentage?.slice(0, -1),
                    refundPercentageDeadline: cancelRefundPercentageAfter,
                  };
                  await api.patch(`charters/${idfret}/cancel-rules`, data);
                }
                setTimeout(
                  () =>
                    navigate(
                      PagePath.AereoSaidasPrecificar.replace(':id', idfret),
                    ),
                  2000,
                );
              }
            })
            .catch((error) => alert(error));
        }
      })
      .catch((error) => console.log(error));
  }

  function submitOnlyOneExcerpt(payload) {
    const selectedExcerptDate = dayjs(payload.excerptDate).add('1', 'day');

    if (selectedExcerptDate <= today) {
      return toaster(
        'warning',
        'Por favor, selecione uma data de ida posterior ou igual a hoje.',
      );
    }

    let query = `
      INSERT INTO vpcharter_fretamento 
        (id, rota_ida, data_frete, contratante, bagagem, ativo) 
      VALUES 
        (NULL, 
        '${payload.excerptRoute.id_rota}', 
        '${payload.excerptDate}', 
        '${session.agency.id}', 
        '${payload.quantityOnlyOneExcerpt}-${payload.poundOnlyOneExcerpt}', 
        ${payload.activeOnlyOneExcerpt ? 1 : 0});
    `;
    let formData = new FormData();
    formData.append('query', query);

    api
      .post('/query', formData)
      .then((response) => {
        if (response.status === 201) {
          toaster('success', 'Saída cadastrada com sucesso!');
          let query =
            "SELECT * FROM `vpcharter_fretamento` where `rota_ida` = '" +
            payload.excerptRoute.id_rota +
            "' and `data_frete` = '" +
            payload.excerptDate +
            "' ";

          let getQueryFormData = new FormData();
          getQueryFormData.append('query', query);

          api
            .post('/query', getQueryFormData)
            .then(async (response) => {
              if (response.data.length > 0) {
                let idfret = response.data[0]['id'];
                if (canChange == 'Y') {
                  const data = {
                    changeWithCost: withCost == 'Y',
                    changeCostValue: changeCost,
                    changeLimitDays: limitDay,
                  };
                  await api.patch(`charters/${idfret}/change-rules`, data);
                }
                if (canCancel == 'Y') {
                  const data = {
                    cancelWithRefund: withRefund == 'Y',
                    refundWithDeadline: withDueDate == 'Y',
                    cancelWithCost: withCost === 'Y',
                    refundPercentage: cancelRefundPercentage,
                    refundDaysLimit: cancelLimitDay,
                    cancelCostPercentage: cancelCostPercentage?.slice(0, -1),
                    refundPercentageDeadline: cancelRefundPercentageAfter,
                  };
                  await api.patch(`charters/${idfret}/cancel-rules`, data);
                }
                setTimeout(
                  () =>
                    navigate(
                      PagePath.AereoSaidasPrecificar.replace(':id', idfret),
                    ),
                  2000,
                );
              }
            })
            .catch((error) => alert(error));
        }
      })
      .catch((error) => console.log(error));
  }

  if (loading) {
    return <LoadingAnimation />;
  }

  const confirmInactivate = () => {
    if (modalOpen.isOnlyOneExcerpt) {
      setValueOnlyOneExcerpt('activeOnlyOneExcerpt', false);
    } else {
      setValue('active', false);
    }
    setModalOpen({
      opened: false,
      isOnlyOneExcerpt: modalOpen.isOnlyOneExcerpt,
    });
  };

  function handleChangeStatus(checked, isOnlyOne) {
    if (checked) {
      setModalOpen({ opened: true, isOnlyOneExcerpt: isOnlyOne });
    } else if (isOnlyOne) {
      return setValueOnlyOneExcerpt('activeOnlyOneExcerpt', true);
    } else {
      return setValue('active', true);
    }
  }

  return (
    <div>
      <div className="flex flex-col gap-7 pb-6">
        <BreadCrumbs
          paths={[
            { label: 'Home', link: PagePath.Home },
            { label: 'Aéreo', link: PagePath.Aereo },
            { label: 'Saídas', link: PagePath.AereoSaidas },
            { label: 'Cadastro de saídas', link: PagePath.Aereo },
          ]}
        />
        <h1 className="m-0 text-heading-1 text-primary">Cadastro de saídas</h1>
      </div>
      <div className="flex flex-col gap-4">
        <div className="w-full rounded-md bg-white">
          <Tab value={currentIndex} onChange={handleChange}>
            <Tab.Heads>
              <Tab.Item label="Ida e volta" index={0} />
              <Tab.Item label="Somente 1 trecho" index={1} />
            </Tab.Heads>
            <Tab.ContentWrapper>
              <Tab.Content index={0}>
                <form
                  onSubmit={handleSubmit(handleSubmitDepartureAndReturn)}
                  className="flex flex-col gap-[15px]"
                >
                  <div className="flex w-full flex-col gap-5 rounded-md !border !border-solid !border-neutral-300 p-[15px]">
                    <Tag
                      value={
                        <div className="flex items-center gap-1">
                          <IoAirplaneOutline /> Rota de ida
                        </div>
                      }
                    />
                    <Controller
                      name="departureRoute"
                      control={control}
                      render={({ field }) =>
                        field.value ? (
                          <div className="flex flex-col gap-2">
                            <label className="m-0 text-sm font-medium text-neutral-800">
                              Rota
                            </label>
                            <RouteCard
                              data={field.value}
                              onClick={() => field.onChange(null)}
                            />
                          </div>
                        ) : (
                          <DropdownListRoutes
                            placeholder="Digite a IATA, Cidade ou Estado"
                            dataSearch={dataBaseRotasOrigem}
                            value={departureOrigin}
                            label="Rota"
                            onChange={(text) => setDepartureOrigin(text)}
                            onSelectRoute={(data) => field.onChange(data)}
                          />
                        )
                      }
                    />
                    <div className="w-[196px]">
                      <Controller
                        name="departureDate"
                        control={control}
                        render={({ field }) => (
                          <InputDate
                            label="Data de ida"
                            onChange={(text) =>
                              field.onChange(text.target.value)
                            }
                            type="date"
                            value={field.value}
                            required
                          />
                        )}
                      />
                    </div>
                  </div>

                  <div className="flex w-full flex-col gap-5 rounded-md !border  !border-solid !border-neutral-300 p-4">
                    <Tag
                      value={
                        <div className="flex items-center gap-1">
                          {' '}
                          <IoAirplaneOutline className="flip" /> Rota de volta
                        </div>
                      }
                    />
                    <Controller
                      name="returnRoute"
                      control={control}
                      render={({ field }) =>
                        field.value ? (
                          <div className="flex flex-col gap-2">
                            <label className="m-0 text-sm font-medium text-neutral-800">
                              Rota
                            </label>
                            <RouteCard
                              data={field.value}
                              onClick={() => field.onChange(null)}
                            />
                          </div>
                        ) : (
                          <DropdownListRoutes
                            placeholder="Digite a IATA, Cidade ou Estado"
                            dataSearch={dataBaseRotasOrigem}
                            value={returnOrigin}
                            label="Rota"
                            onChange={(text) => setReturnOrigin(text)}
                            onSelectRoute={(data) => field.onChange(data)}
                          />
                        )
                      }
                    />
                    <div className="w-[196px]">
                      <Controller
                        name="returnDate"
                        control={control}
                        render={({ field }) => (
                          <InputDate
                            label="Data de volta"
                            onChange={(event) =>
                              field.onChange(event.target.value)
                            }
                            value={field.value}
                            required
                          />
                        )}
                      />
                    </div>
                  </div>

                  <div className="flex w-full flex-col gap-5 rounded-md !border  !border-solid !border-neutral-300 p-[15px]">
                    <span className="text-base font-semibold text-primary">
                      Bagagem para despachar
                    </span>
                    <div className="flex w-full gap-4">
                      <div className="w-[129px]">
                        <Controller
                          name="quantity"
                          control={control}
                          render={({ field }) => (
                            <Select
                              label="Quantidade"
                              value={field.value}
                              options={quantity}
                              onChange={field.onChange}
                            />
                          )}
                        />
                      </div>
                      <div className="w-[301px]">
                        <Controller
                          name="pound"
                          control={control}
                          render={({ field }) => (
                            <InputText
                              label="Peso em kg"
                              placeholder="Insira o peso da bagagem em kg"
                              onChange={(e) => {
                                const value = e.target.value;
                                if (/^[0-9,{1}]*$/.test(value)) {
                                  field.onChange(value);
                                }
                              }}
                              value={field.value}
                            />
                          )}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex w-full flex-col gap-5 rounded-md !border  !border-solid !border-neutral-300 p-[15px]">
                    <span className="text-base font-semibold text-primary">
                      Regras de alteração
                    </span>
                    <div className="flex w-full gap-4">
                      <div className="w-[129px]">
                        <label className="mt-0">Poderá alterar?</label>
                      </div>
                      <div className="w-[70px]">
                        <InputRadio
                          label="Sim"
                          checked={canChange == 'Y'}
                          onChange={() => setCanChange('Y')}
                        />
                      </div>
                      <div className="w-[129px]">
                        <InputRadio
                          label="Não"
                          checked={canChange == 'N'}
                          onChange={() => setCanChange('N')}
                        />
                      </div>
                    </div>
                    {canChange == 'Y' && (
                      <div className="flex w-full gap-4">
                        <div className="w-[180px]">
                          <label className="mt-0">Com custo adicional?</label>
                        </div>
                        <div className="w-[70px]">
                          <InputRadio
                            label="Sim"
                            checked={withCost == 'Y'}
                            onChange={() => setWithCost('Y')}
                          />
                        </div>
                        <div className="w-[129px]">
                          <InputRadio
                            label="Não"
                            checked={withCost == 'N'}
                            onChange={() => setWithCost('N')}
                          />
                        </div>
                      </div>
                    )}
                    {withCost == 'Y' && (
                      <div className="flex w-full gap-4">
                        <div className="w-[70px]">
                          <label>Custo de</label>
                        </div>
                        <div className="w-[120px]">
                          <IntlCurrencyInput
                            currency="BRL"
                            config={currencyConfig}
                            placeholder="Valor em R$"
                            className="currency mt-1 text-sm"
                            value={changeCost}
                            onChange={handleChangeCost}
                          />
                        </div>
                      </div>
                    )}
                    {withCost == 'N' && (
                      <div className="flex w-full gap-4">
                        <div className="w-[30px]">
                          <label>Até</label>
                        </div>
                        <div className="w-[140px]">
                          <InputText
                            placeholder="Quantos dias?"
                            className="mt-1"
                            value={limitDay}
                            onChange={handleLimitDay}
                          />
                        </div>
                        <div className="w-[360px]">
                          <label>
                            dias antes da data de partida. Antes com custo de
                          </label>
                        </div>
                        <div className="w-[150px]">
                          <IntlCurrencyInput
                            currency="BRL"
                            config={currencyConfig}
                            placeholder="Valor em R$"
                            className="currency mt-1 text-sm"
                            value={changeCost}
                            onChange={handleChangeCost}
                          />
                        </div>
                      </div>
                    )}
                    {errors?.change && (
                      <div className="flex w-full gap-4">
                        <Form.Message error={errors?.change} />
                      </div>
                    )}
                  </div>
                  <div className="flex w-full flex-col gap-5 rounded-md !border  !border-solid !border-neutral-300 p-[15px]">
                    <span className="text-base font-semibold text-primary">
                      Regras de cancelamento
                    </span>
                    <div className="flex w-full gap-4">
                      <div className="w-[150px]">
                        <label className="mt-0">Poderá cancelar?</label>
                      </div>
                      <div className="w-[70px]">
                        <InputRadio
                          label="Sim"
                          checked={canCancel == 'Y'}
                          onChange={() => setCanCancel('Y')}
                        />
                      </div>
                      <div className="w-[129px]">
                        <InputRadio
                          label="Não"
                          checked={canCancel == 'N'}
                          onChange={() => setCanCancel('N')}
                        />
                      </div>
                    </div>
                    {canCancel == 'Y' && (
                      <div className="flex w-full gap-4">
                        <div className="w-[150px]">
                          <label className="mt-0">Com reembolso?</label>
                        </div>
                        <div className="w-[70px]">
                          <InputRadio
                            label="Sim"
                            checked={withRefund == 'Y'}
                            onChange={() => setWithRefund('Y')}
                          />
                        </div>
                        <div className="w-[129px]">
                          <InputRadio
                            label="Não"
                            checked={withRefund == 'N'}
                            onChange={() => setWithRefund('N')}
                          />
                        </div>
                      </div>
                    )}
                    {canCancel == 'Y' && withRefund == 'N' && (
                      <div className="flex w-full gap-4">
                        <div className="w-[170px]">
                          <label className="mt-0">Com custo adicional?</label>
                        </div>
                        <div className="w-[70px]">
                          <InputRadio
                            label="Sim"
                            checked={withAditionalCost == 'Y'}
                            onChange={() => setWithAditionalCost('Y')}
                          />
                        </div>
                        <div className="w-[129px]">
                          <InputRadio
                            label="Não"
                            checked={withAditionalCost == 'N'}
                            onChange={() => setWithAditionalCost('N')}
                          />
                        </div>
                      </div>
                    )}
                    {canCancel == 'Y' &&
                      withRefund == 'N' &&
                      withAditionalCost == 'Y' && (
                        <div className="flex w-full gap-4">
                          <div className="w-[170px]">
                            <label>Com custo adicional de</label>
                          </div>
                          <div className="w-[90px]">
                            <InputText
                              placeholder="Ex: 10%"
                              className="mt-1"
                              value={cancelCostPercentage}
                              onChange={handleCancelCostPercentage}
                            />
                          </div>
                          <div className="w-[150px]">
                            <label>do valor da tarifa.</label>
                          </div>
                        </div>
                      )}

                    {canCancel == 'Y' && withRefund == 'Y' && (
                      <div className="flex w-full gap-4">
                        <div className="w-[150px]">
                          <label className="mt-0">Com data limite?</label>
                        </div>
                        <div className="w-[70px]">
                          <InputRadio
                            label="Sim"
                            checked={withDueDate == 'Y'}
                            onChange={() => setWithDueDate('Y')}
                          />
                        </div>
                        <div className="w-[129px]">
                          <InputRadio
                            label="Não"
                            checked={withDueDate == 'N'}
                            onChange={() => setWithDueDate('N')}
                          />
                        </div>
                      </div>
                    )}

                    {canCancel == 'Y' &&
                      withRefund == 'Y' &&
                      withDueDate == 'N' && (
                        <div className="flex w-full gap-4">
                          <div className="w-[115px]">
                            <label>Reembolso de</label>
                          </div>
                          <div className="w-[90px]">
                            <InputText
                              placeholder="Ex: 10%"
                              className="mt-1"
                              value={cancelRefundPercentage}
                              onChange={handleCancelRefundPercentage}
                            />
                          </div>
                          <div className="w-[150px]">
                            <label>do valor da tarifa.</label>
                          </div>
                        </div>
                      )}

                    {canCancel == 'Y' &&
                      withRefund == 'Y' &&
                      withDueDate == 'Y' && (
                        <>
                          <div className="flex w-full gap-4">
                            <div className="w-[115px]">
                              <label>Reembolso de</label>
                            </div>
                            <div className="w-[90px]">
                              <InputText
                                placeholder="Ex: 10%"
                                className="mt-1"
                                value={cancelRefundPercentage}
                                onChange={handleCancelRefundPercentage}
                              />
                            </div>
                            <div className="w-[150px]">
                              <label>do valor da tarifa até </label>
                            </div>
                            <div className="w-[140px]">
                              <InputText
                                placeholder="Quantos dias?"
                                className="mt-1"
                                value={cancelLimitDay}
                                onChange={handleCancelLimitDay}
                              />
                            </div>
                            <div className="w-[230px]">
                              <label>dias antes da data de partida. </label>
                            </div>
                          </div>
                          <div className="flex w-full gap-4">
                            <div className="w-[250px]">
                              <label>Após, será reembolsado soemente</label>
                            </div>
                            <div className="w-[90px]">
                              <InputText
                                placeholder="Ex: 10%"
                                className="mt-1"
                                value={cancelRefundPercentageAfter}
                                onChange={handleCancelRefundPercentageAfter}
                              />
                            </div>
                            <div className="w-[150px]">
                              <label>da tarifa. </label>
                            </div>
                          </div>
                        </>
                      )}
                    {errors?.cancel && (
                      <div className="flex w-full gap-4">
                        <Form.Message error={errors?.cancel} />
                      </div>
                    )}
                  </div>
                  <Controller
                    name="active"
                    control={control}
                    render={({ field }) => (
                      <Switch
                        label="Ativa"
                        checked={field.value}
                        onChange={() => handleChangeStatus(field.value, false)}
                      />
                    )}
                  />
                  <div className="flex w-full justify-end">
                    <Button
                      label="Salvar"
                      className="w-max px-[75px]"
                      type="submit"
                    />
                  </div>
                </form>
              </Tab.Content>
              <Tab.Content index={1}>
                <form
                  onSubmit={handleSubmitOnlyOneExcerpt(submitOnlyOneExcerpt)}
                  className="flex flex-col gap-[15px]"
                >
                  <div className="flex w-full flex-col gap-5 rounded-md !border !border-solid !border-neutral-300 p-4">
                    <Tag
                      value={
                        <div className="flex items-center gap-1">
                          <IoAirplaneOutline /> Trecho
                        </div>
                      }
                    />
                    <Controller
                      name="excerptRoute"
                      control={controlOnlyOneExcerpt}
                      render={({ field }) =>
                        field.value ? (
                          <>
                            <label className="m-0 text-sm font-medium text-neutral-800">
                              Rota
                            </label>
                            <RouteCard
                              data={field.value}
                              onClick={() => field.onChange(null)}
                            />
                          </>
                        ) : (
                          <DropdownListRoutes
                            placeholder="Digite a IATA, Cidade ou Estado"
                            dataSearch={dataBaseRotasOrigem}
                            value={departureOrigin}
                            label="Rota"
                            onChange={(text) => setDepartureOrigin(text)}
                            onSelectRoute={(data) => field.onChange(data)}
                          />
                        )
                      }
                    />
                    <div className="w-[196px]">
                      <Controller
                        name="excerptDate"
                        control={controlOnlyOneExcerpt}
                        render={({ field }) => (
                          <InputDate
                            label="Data do trecho"
                            onChange={(text) =>
                              field.onChange(text.target.value)
                            }
                            value={field.value}
                            required
                          />
                        )}
                      />
                    </div>
                  </div>

                  <div className="flex w-full flex-col gap-5 rounded-md !border !border-solid !border-neutral-300 p-[15px]">
                    <span className="text-base font-semibold text-primary">
                      Bagagem para despachar
                    </span>
                    <div className="flex w-full gap-4">
                      <div className="w-[129px]">
                        <Controller
                          name="quantityOnlyOneExcerpt"
                          control={controlOnlyOneExcerpt}
                          render={({ field }) => (
                            <Select
                              label="Quantidade"
                              value={field.value}
                              options={quantity}
                              onChange={field.onChange}
                            />
                          )}
                        />
                      </div>
                      <div className="w-[301px]">
                        <Controller
                          name="poundOnlyOneExcerpt"
                          control={controlOnlyOneExcerpt}
                          render={({ field }) => (
                            <InputText
                              label="Peso em kg"
                              placeholder="Insira o peso da bagagem em kg"
                              onChange={(e) => {
                                const value = e.target.value;
                                if (/^[0-9,{1}]*$/.test(value)) {
                                  field.onChange(value);
                                }
                              }}
                              value={field.value}
                            />
                          )}
                        />
                      </div>
                    </div>
                  </div>
                  <Controller
                    name="activeOnlyOneExcerpt"
                    control={controlOnlyOneExcerpt}
                    render={({ field }) => (
                      <Switch
                        label="Ativa"
                        checked={field.value}
                        onChange={() => handleChangeStatus(field.value, true)}
                      />
                    )}
                  />
                  <div className="flex w-full justify-end">
                    <Button
                      label="Salvar"
                      className="w-max px-[75px]"
                      type="submit"
                    />
                  </div>
                </form>
              </Tab.Content>
            </Tab.ContentWrapper>
          </Tab>
        </div>
      </div>
      <Modal open={modalOpen.opened}>
        <div className="flex flex-col items-center justify-center px-[190px] py-[60px]">
          <GoAlertFill className="text-warning-500" size={38} />
          <span className="mt-3 text-center text-heading-1 text-warning-500">
            Tem certeza que deseja inativar essa saída?
          </span>
          <p className="mb-[30px] mt-[15px] text-center text-heading-4 text-neutral-800">
            Ao inativar essa saída, ela será ocultada da listagem de
            disponibilidades de reservas.
          </p>
          <div className="mt-3 flex items-center justify-center gap-[30px]">
            <Button
              variant="ghost"
              label="Cancelar"
              onClick={() => setModalOpen((prev) => !prev)}
            />
            <Button label="Inativar saída" onClick={confirmInactivate} />
          </div>
        </div>
      </Modal>
    </div>
  );
}
